<template>
  <div>
    <div>
      <a-row>
        <a-col :span="12">
          <a-form-item label="服务号">
            <span>{{ listData.serviceNumber || '-' }}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客户姓名">
            <span>{{ listData.customerName || '-' }}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="服务人">
            <span>{{ listData.serviceEmployees || '-' }}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="创建时间">
            <span>{{ getDate(listData.createdDate) || '-' }}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="结束时间">
            <span>{{ (listData.endYear ? (listData.endYear + '年') : '') + (listData.endSeasonName ? listData.endSeasonName : '') }}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="录取结果">
            <span>{{ listData.admissionResult || '-' }}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="备注">
            <span>{{ listData.remark }}</span>
          </a-form-item>
        </a-col>
      </a-row>
    </div>

    <a-tabs v-model:activeKey="activeKey" type="card" style="margin-top: 50px;">
      <a-tab-pane :key="1" tab="申请项目">
        <a-table :dataSource="applications" :columns="columns1" />
      </a-tab-pane>
      <a-tab-pane :key="2" tab="标化成绩">
        <a-table :dataSource="achievements" :columns="columns2" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { get } from "@/utils/http";
import TablePage from "@/components/TablePage";
import { customerStage, intentionLevel, customerStatus, starRating } from '@/utils/public';
import dayjs from "dayjs";
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "intentionDetail",
  data() {
    return {
      detailLoading: false,
      listData: {},
      activeKey: 1,
      columns1: [
        {
          title: '项目名',
          dataIndex: 'name',
          
        },
        {
          title: '申请阶段',
          dataIndex: 'stage',
          width: '130px',
        },
        {
          title: '项目备注',
          dataIndex: 'remark',
        },
      ],
      columns2: [
        {
          title: '项目名',
          dataIndex: 'name',
        },
        {
          title: '项目备注',
          dataIndex: 'remark',
        },
      ],
      applications: [],
      achievements: []
    }
  },
  created() {
    this.getDetail(this.selectedRows[0])
  },
  methods: {
    async getDetail(row) {
      this.detailLoading = true;
      try {
        let res = await get(`/institutions/aftermarket/get?id=${row.id}`)
        this.listData = res.data;
        this.applications = res.data.applications;
        this.achievements = res.data.achievements;

      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },

    getDate(val) {
      if (val) {
        return dayjs(val).format('YYYY-MM-DD: HH:mm:ss')
      } else {
        return '-'
      }
    }

  }
}
</script>

<style scoped>
.detail-title {
  font-weight: 550;
}

.detail-text {}

.ant-col {
  margin-top: 20px;
}

.user_image {
  max-width: 224px;
  max-height: 126px;
}
</style>
